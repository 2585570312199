import {ChakraProvider} from '@chakra-ui/react';
import {withEmotionCache} from '@emotion/react';
import type {LinksFunction, MetaFunction} from '@remix-run/node'; // Depends on the runtime you choose
import {Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLocation, useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';
import type React from 'react';
import {useContext, useEffect} from 'react';
import {AdminLayout} from '#/app/components/AdminLayout';
import {DefaultLayout} from '#/app/components/DefaultLayout';
import {ClientStyleContext, ServerStyleContext} from '#/app/helpers/Emotion/context';
import {theme} from '#/app/theme';
import '#/app/styles.css';

export const meta: MetaFunction = () => {
  return [
    {charSet: 'utf-8'},
    {title: 'KC Kosher Coop - Administration'},
    {content: 'width=device-width, initial-scale=1', name: 'viewport'},
  ];
};

export const links: LinksFunction = () => {
  return [
    {href: 'https://fonts.googleapis.com', rel: 'preconnect'},
    {href: 'https://fonts.gstatic.com', rel: 'preconnect'},
    {
      href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap',
      rel: 'stylesheet',
    },
  ];
};

interface DocumentProps {
  children: React.ReactNode;
  title?: string;
}

const Document = withEmotionCache(({children, title}: DocumentProps, emotionCache) => {
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {pathname} = useLocation();
  const Layout = pathname.indexOf('/admin') === 0 ? AdminLayout : DefaultLayout;

  return (
    <html lang="en" suppressHydrationWarning>
      <head>
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
        {serverStyleData?.map(({css, ids, key}) => (
          <style dangerouslySetInnerHTML={{__html: css}} data-emotion={`${key} ${ids.join(' ')}`} key={key} />
        ))}
      </head>
      <body>
        <Layout>{children}</Layout>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
});

// TODO: Improve error handling
export function ErrorBoundary() {
  const error = useRouteError();

  console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
  console.log(error);

  captureRemixErrorBoundaryError(error);
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <html className="h-full" lang="en">
        <head>
          <title>{error.statusText}</title>
          <Meta />
          <Links />
        </head>
        <body className="h-full">
          <div className="size-screen p-8 font-mono">
            <div className="space-y-5 border-4 border-red-500 p-6">
              <div className="text-xl font-bold text-red-500">{error.status}</div>
              <pre className="text-sm text-red-500">{error.statusText || error.data}</pre>
            </div>
          </div>
        </body>
      </html>
    );
  }

  if (error instanceof Error) {
    return (
      <html className="h-full" lang="en">
        <head>
          <title>{error.message}</title>
          <Meta />
          <Links />
        </head>
        <body className="h-full">
          <div className="size-screen p-8 font-mono">
            <div className="space-y-5 border-4 border-red-500 p-6">
              <div className="text-xl font-bold text-red-500">{error.message}</div>
              <pre className="text-sm text-red-500">{error.stack}</pre>
            </div>
          </div>
        </body>
      </html>
    );
  }
  return <h1>Unknown Error</h1>;
}

export default function App() {
  return (
    <Document>
      <ChakraProvider theme={theme}>
        <Outlet />
      </ChakraProvider>
    </Document>
  );
}
